import { HttpContextToken } from '@angular/common/http';

// Interceptors
export const SKIP_ERROR_INTERCEPTOR = new HttpContextToken<boolean>(
  () => false
);
export const SKIP_JWT_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

// Others
export const REMEMBER_ME_STATUS_KEY = 'auth_remember_me';
export const CURRENT_USER = 'current_user';
export const TOKEN = 'access_token_admin7653GHS!';
export const STOCK_PRICE = 30000;