<div class="notification-box" (click)="toggleNotificationMobile()">
  <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg>
  <span class="badge rounded-pill badge-secondary">0</span>
</div>
<div class="onhover-show-div notification-dropdown">
  <h6 class="f-18 mb-0 dropdown-title">Notifications</h6>
  <ul>
    <li>
      <p class="no-notification">Aucune notification</p>
    </li>
    <!-- <li class="b-l-primary border-4">
      <p>Delivery processing <span class="font-danger">10 min.</span></p>
    </li>
    <li class="b-l-success border-4">
      <p>Order Complete<span class="font-success">1 hr</span></p>
    </li>
    <li class="b-l-secondary border-4">
      <p>Tickets Generated<span class="font-secondary">3 hr</span></p>
    </li>
    <li class="b-l-warning border-4">
      <p>Delivery Complete<span class="font-warning">6 hr</span></p>
    </li> -->
    <li><a class="f-w-700" href="#">Check all</a></li>
  </ul>
</div>
