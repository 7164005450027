import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, tap } from "rxjs";
import { AuthService } from "src/app/shared/services/auth.service";
import * as dayjs from 'dayjs'
import { HttpErrorResponse } from "@angular/common/http";
import { setItem, setRememberMeStatus } from "src/app/shared/utils/storage";
import { CURRENT_USER, TOKEN } from "src/app/shared/constants/global.constants";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;
  public currentYear = dayjs().year();

  isLoading = false;
  setRememberMeStatus = setRememberMeStatus;

  constructor(private fb: FormBuilder, public router: Router, private authService: AuthService, private cdRef: ChangeDetectorRef) {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      remerberMe: [false]
    });
  }

  get canLogin(): boolean {
    return this.loginForm.valid;
  }

  ngOnInit(): void {
      this._handleNavigation();
  }

  login() {
    this.isLoading = true;
    this.cdRef.detectChanges();
    const { remerberMe, ...value } = this.loginForm.value;
    this.authService.login({...value})
    .pipe(
      finalize(() => {
        this.isLoading = false;
        this.cdRef.detectChanges();
      }),
      tap({
        next: res => {
          setItem(CURRENT_USER, JSON.stringify(res.data));
          setItem(TOKEN, res.token);
          this.router.navigate(["/dashboard"]);
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 422) {
            const errors = err.error.errors as Record<string, Array<string>>;
            this._setFormError(errors);
            this.cdRef.detectChanges();
          }
          
          if (err.status === 403) {
            this.errorMessage = err.error.message;
            this.cdRef.detectChanges();
          }
        }
      })
    ).subscribe();
  }

  showPassword(){
    this.show = !this.show
  }

  private _setFormError(errors: Record<string, Array<string>> |  undefined): void {
    if (!errors) return;
    Object.entries(errors).forEach(([path, value]) => {
      const control = this.loginForm.get(path);
      control?.setErrors({httpError: value[0]});
      control?.markAsTouched();
      control?.markAsDirty();
    });
  }

  private _handleNavigation(): void {
    const state = this.router.getCurrentNavigation()?.extras.state;
    this.errorMessage = state?.unauthenticated;
    this.cdRef.detectChanges();
  }
}
