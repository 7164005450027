<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" />
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Connexion</h4>
              <p>Entrez votre email et mot de passe pour vous connecter</p>

              <ng-container *ngIf="errorMessage">
                <ngb-alert [type]="'danger'" [dismissible]="false">
                  {{ errorMessage }}
                </ngb-alert>
              </ng-container>

              <div class="form-group">
                <label class="col-form-label">Adresse email</label>
                <input class="form-control" type="email" placeholder="Entrez votre adresse email" formControlName="email" />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">Email est requis</div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">Email invalide</div>
                <div *ngIf="loginForm.controls.email.errors?.httpError as httpError" class="text text-danger mt-1">{{ httpError }}</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Mot de pasee</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" placeholder="*********" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">Password est requis</div>
                <div *ngIf="loginForm.controls.password.errors?.httpError as httpError" class="text text-danger mt-1">{{ httpError }}</div>
              </div>
              <div class="form-group mb-0">
                <div class="p-0">
                  <input id="remember-me" type="checkbox" formControlName="remerberMe" (change)="setRememberMeStatus($event.target.checked)" />
                  <label class="text-muted ms-1" for="remember-me" style="color: #2F2F3B !important;">Se souvenir de moi</label>
                </div>
                <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Mot de passe oublié?</a> -->
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!canLogin || isLoading" (click)="login()" type="button">
                    <span *ngIf="!isLoading">Se connecter</span>
                    <span *ngIf="isLoading">Connexion...</span>
                  </button>
                </div>
              </div>
              <p class="mt-4 mb-0 text-center">
                Copyright {{ currentYear }} © 1000 Vendeurs
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
