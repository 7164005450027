import { REMEMBER_ME_STATUS_KEY } from "../constants/global.constants";

export const getItem = (key: string): string | null => {
    if (isRememberMeStatusSet()) {
      return localStorage.getItem(key);
    } else {
      return sessionStorage.getItem(key);
    }
  }

  export const setItem = (key: string, val: string): void => {
    if (isRememberMeStatusSet()) {
      localStorage.setItem(key, val);
    } else {
      sessionStorage.setItem(key, val);
    }
  }

  export const removeItem = (key: string): void => {
    if (isRememberMeStatusSet()) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  export const clear = (): void  => {
    if (isRememberMeStatusSet()) {
      localStorage.clear();
    } else {
      sessionStorage.clear();
    }
  }

export const setRememberMeStatus = (set: boolean): void => {
  set
    ? localStorage.setItem(REMEMBER_ME_STATUS_KEY, 'true')
    : localStorage.removeItem(REMEMBER_ME_STATUS_KEY);
};

export const isRememberMeStatusSet = (): boolean => {
  return !!localStorage.getItem(REMEMBER_ME_STATUS_KEY);
};