import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, tap } from "rxjs";
import { clear, getItem } from "../utils/storage";
import { SKIP_JWT_INTERCEPTOR, TOKEN } from "../constants/global.constants";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('Authorization') || req.context.get(SKIP_JWT_INTERCEPTOR) === true) return next.handle(req);

        const token = getItem(TOKEN);
        if (token) {
            return next.handle(
                req.clone({setHeaders: { authorization: `Bearer ${token}`}})
            ).pipe(
                tap({
                    error: (error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            clear();
                            this.router.navigate(['/auth/login'], {state: {unauthenticated: 'Veuillez vous connecter!'}});
                        }
                    }
                })
            );
        }

        return next.handle(req).pipe(
            tap({
                error: err => {
                    if (err.status === 401) {
                        this.router.navigate(['/auth/login'], {state: {unauthenticated: 'Veuillez vous connecter!'}});
                    }
                }
            })
        );
    }
}