import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ListParams, ListResponse } from '../models/shared.models';
import { MEMBERSHIP_REQUEST_URL, SELLERS_URL } from '../constants/api.constants';
import { Customer, MembershipRequest, Seller, SellerCreationParams } from '../models/seller.models';
import { Observable, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class SellersService {
    constructor(private http: HttpClient, private toastr: ToastrService) {}

    list(params?: Partial<ListParams>): Observable<ListResponse<Seller>> {
        return this.http.get<ListResponse<Seller>>(SELLERS_URL, {params});
    }

    updateStatus(id: string, data: {status: boolean, note?: string}): Observable<unknown> {
        return this.http.put<unknown>(`${SELLERS_URL}/${id}/status`, data);
    }

    sellerCustomerList(id: string): Observable<ListResponse<Customer>> {
        return this.http.get<ListResponse<Customer>>(`${SELLERS_URL}/${id}/customers`);
    }

    sellerInviteeList(id: string): Observable<ListResponse<Seller>> {
        return this.http.get<ListResponse<Seller>>(`${SELLERS_URL}/${id}/invitees`);
    }

    create(data: SellerCreationParams): Observable<unknown> {
        return this.http.post<unknown>(SELLERS_URL, data);
    }

    membershipRequestList(params?: Partial<ListParams>): Observable<ListResponse<MembershipRequest>> {
        return this.http.get<ListResponse<MembershipRequest>>(`${MEMBERSHIP_REQUEST_URL}`, {params});
    }
}