export const BASE_API_URL = 'https://api.1000vendeurs.academy/api/admin';

// Secutity
export const AUTH_URL = `${BASE_API_URL}/login`;

// Order
export const ORDERS_URL = `${BASE_API_URL}/orders`;

// Seller
export const SELLERS_URL = `${BASE_API_URL}/sellers`;

// Transaction
export const TRANSACTIONS_URL = `${BASE_API_URL}/payments`;

// Membership Request
export const MEMBERSHIP_REQUEST_URL = `${BASE_API_URL}/registrations`;