import { Injectable, inject } from "@angular/core";
import { AuthResponse, LoginParams } from "../models/auth.models";
import { HttpClient, HttpContext } from "@angular/common/http";
import { AUTH_URL } from "../constants/api.constants";
import { Observable } from "rxjs";
import { SKIP_JWT_INTERCEPTOR } from "../constants/global.constants";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _http = inject(HttpClient);

    login(data: LoginParams): Observable<AuthResponse> {
        return this._http.post<AuthResponse>(AUTH_URL, data, { context: new HttpContext().set(SKIP_JWT_INTERCEPTOR, true)});
    }
}