/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Define here models used
 * across app that are not defined by the backend
 **/

export type Place = {
  country: string;
  city: string;
  address: string;
};

export enum OrderStatus {
  pending = 'En attente',
  confirmed = 'Confirmé',
  delivered = 'livré',
  canceled = 'Refusé'
};

export enum Status {
  pending = 'pending',
  confirmed = 'confirmed',
  delivered = 'delivered',
  canceled = 'canceled'
};

export type ListParams = {
  page: number;
  per_page: number;
  status: string;
};

export type ListResponse<T> = {
  data: Array<T>;
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    per_page: number;
    to: number | null;
    total: number;
    [key: string]: unknown;
  }
};

export enum Page {
  SELLER_LIST = 'SELLER_LIST',
  MEMBERSHIP_REQUEST = 'MEMBERSHIP_REQUEST',
  ORDER_LIST = 'ORDER_LIST',
  TRANSACTION_LIST = 'TRANSACTION_LIST'
};

export enum Gender {
  male = 'male',
  female = 'female'
};

export enum Tshirt {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl'
}
