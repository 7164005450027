import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { clear } from "src/app/shared/utils/storage";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router) {}

  logoutFunc() {
    clear();
    this.router.navigateByUrl('auth/login');
  }
}
